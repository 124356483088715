import React, { useState, useEffect, useRef } from 'react'
import '../styles/Details.css'
import { Link } from 'react-router-dom'

import { EditOutlined, FilterOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Table, Tag, Tooltip, Spin, Modal, Card, Input } from 'antd'
import { convertToTitleCase } from '../utils/stringUtils'
import { getAgePoints, getAllData, getSpecialization, getTreatmentPreferences } from '../services/ClinicianService'
import { getStates } from '../services/StateService'
import { getInsurances } from '../services/InsuranceService'
import FilterComponent from '../components/FilterComponent'
import { categorizeAppointment } from '../utils/categorizeAppointment'
import useDebounce from '../hooks/useDebounce'

const { Search } = Input
const { Column } = Table

function CliniciansDetails() {
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [totalItems, setTotalItems] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalComponent, setModalComponent] = useState()
  const [data, setData] = useState([])
  const [stateDropDown, setStateDropDown] = useState([])
  const [insuranceDropDown, setInsuranceDropDown] = useState([])
  const [apptTypeDropDown, setApptTypeDropDown] = useState([])
  const [searchField, setSearchField] = useState('')
  const [stateSelected, setStateSelected] = useState([])
  const [insuranceSelected, setInsuranceSelected] = useState([])
  const [apptTypeSelected, setApptTypeSelected] = useState([])
  const [availableBookingStatus, setAvailableBookingStatus] = useState(null)
  const [timezone, setTimezone] = useState(null)
  const [languageSelected, setLanguageSelected] = useState([])
  const [specializationSelected, setSpecializationSelected] = useState([])
  const [specializationDropDown, setSpecializationDropDown] = useState([])
  const [agePointsSelected, setAgePointsSelected] = useState([])
  const [agePointsDropDown, setAgePointsDropDown] = useState([])
  const [treatmentPreferences, setTreatmentPreferences] = useState([])
  const [treatmentsDropDown, setTreatmentsDropDown] = useState([])

  const [loading, setLoading] = useState(false)

  const filterCardRef = useRef(null)
  const filterButtonRef = useRef(null)

  const handleClickOutside = (event) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false)
    }
  }

  const handleTableChange = (pagination, filters) => {
    setLoading(true)
    setCurrentPage(pagination.current)
    setPageLimit(pagination.pageSize)
    setLoading(false)
    // setData([])
    // data.length = 0
    // loadData(pagination.current, pagination.pageSize);
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  async function handleStateInsuranceDetails(stateInsurance) {
    setModalTitle(`Availabe State and Insurance Details`)
    setModalComponent(
      <div>
        <ul>
          {Object.keys(stateInsurance).length !== 0
            ? Object.entries(stateInsurance).map(([state, insurances]) => (
              <li key={state}>
                {state}:
                <ul>
                  {insurances.map((insurance) => (
                    <li key={insurance}>{insurance}</li>
                  ))}
                </ul>
              </li>
            ))
            : 'Details not Available'}
        </ul>
      </div>
    )
    setIsModalOpen(true)
  }

  async function loadData(pageNo, pageSize, data) {
    try {
      setLoading(true)
      const details = await getAllData(pageNo, pageSize, 'get-providers', data)
      const stateData = await getStates()
      const stateNameMap = {}
      setStateDropDown(stateData)
      stateData.forEach((state) => {
        stateNameMap[state.id] = state.title
      })

      const insuranceMap = {}
      const insuranceData = await getInsurances()
      setInsuranceDropDown(insuranceData)
      insuranceData.forEach((insurance) => {
        insuranceMap[insurance.id] = insurance.title
      })

      const specialization = await getSpecialization()
      setSpecializationDropDown(specialization.specializations)
      const agepoints = await getAgePoints()
      setAgePointsDropDown(agepoints.agepoints)

      const treatments = await getTreatmentPreferences()
      setTreatmentsDropDown(treatments.treatments)

      const appointmentData = await getAllData(1, 1, 'get-appt-types')

      const appointmentMap = {}
      setApptTypeDropDown(appointmentData)
      appointmentData.forEach((appointment) => {
        appointmentMap[appointment.id] = appointment.name
      })

      setTotalItems(details.totalItems === 0 ? 1 : details.totalItems)
      if (details.data.length) {
        setData(
          details.data
            .map((element) => {
              const stateInsurance = {}
              if (element.provider_state_insurance.length !== 0) {
                element.provider_state_insurance.map((item) => {
                  const { state_skey, insurance_list_skey } = item
                  if (!stateInsurance[stateNameMap[state_skey]]) {
                    stateInsurance[stateNameMap[state_skey]] = [insuranceMap[insurance_list_skey]]
                  } else {
                    stateInsurance[stateNameMap[state_skey]].push(insuranceMap[insurance_list_skey])
                  }
                })
              }

              const appointmentTypeUnique = new Set()

              if (element.provider_appt_types.length !== 0) {
                element.provider_appt_types.map((item) => {
                  const { appt_type_skey } = item
                  appointmentTypeUnique.add(categorizeAppointment(appointmentMap[appt_type_skey]))
                })
              }
              const appointmentType = [...appointmentTypeUnique]
              return {
                key: element.id,
                id: element.id,
                clinician: element.provider_firstname
                  ? convertToTitleCase(element.provider_firstname) + ' ' + convertToTitleCase(element.provider_lastname)
                  : element.provider_column_heading,
                email: element.provider_email ? element.provider_email.toLowerCase() : '--',
                clinicianTitle: element.provider_title ? element.provider_title : '--',
                clinicianStatus: element.provider_status,
                createdAt: element.provider_created_at,
                appointmentTypes: appointmentType.toString(),
                stateInsurance: stateInsurance,
                doxyLink: element.provider_settings ? element.provider_settings.doxy_link : null,
                bookingStatus: bookingStatus(element),
                timezone: element.provider_settings ? element.provider_settings.timezone : null,
                language: element.provider_settings ? element.provider_settings.language : null,
                specialization: element.provider_settings ? element.provider_settings?.specialization : null,
                agepoints: element.provider_settings ? element.provider_settings?.agepoints : null,
                treatments: element.provider_settings ? element.provider_settings?.treatments : null,
                specialNotes: element.provider_settings ? element.provider_settings.special_notes : null
              }
            })
            .sort((a, b) => a.clinician.localeCompare(b.clinician))
        )
      }
    } catch (e) {
      console.log('failed to load data from database', e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    } finally {
      setLoading(false) // Reset loading once data fetching is complete
    }
    return true
  }

  const debouncedSearchValue = useDebounce(searchField)

  useEffect(() => {
    setCurrentPage(1)
    setPageLimit(10)
    setData([])
    data.length = 0

    const filter = {}

    if (stateSelected.length !== 0) {
      filter['selectedState'] = stateSelected.toString()
    }

    if (insuranceSelected.length !== 0) {
      filter['selectedInsurance'] = insuranceSelected.toString()
    }

    if (apptTypeSelected.length !== 0) {
      filter['selectedApptType'] = apptTypeSelected.toString()
    }

    if (debouncedSearchValue.length !== 0) {
      filter['searchField'] = debouncedSearchValue
    }

    if (availableBookingStatus) {
      filter['bookingStatus'] = availableBookingStatus
    }

    if (timezone) {
      filter['timezone'] = timezone
    }

    if (languageSelected.length !== 0) {
      filter['language'] = languageSelected.toString()
    }

    if (specializationSelected.length !== 0) {
      filter['specialization'] = specializationSelected.toString()
    }

    if (agePointsSelected.length !== 0) {
      filter['agepoints'] = agePointsSelected.toString()
    }

    if (treatmentPreferences.length !== 0) {
      filter['treatments'] = treatmentPreferences.toString()
    }

    loadData(1, 10, filter)
  }, [
    debouncedSearchValue,
    stateSelected,
    insuranceSelected,
    apptTypeSelected,
    availableBookingStatus,
    timezone,
    languageSelected,
    specializationSelected,
    agePointsSelected,
    treatmentPreferences
  ])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleFilter = () => {
    setIsFilterVisible(true)
  }

  const handleClose = () => {
    setIsFilterVisible(false)
  }

  const handleSearch = (value) => {
    setSearchField(value)
  }

  const bookingStatus = (element) => {
    return element.provider_settings
      ? element.provider_settings.booking_available_status === 'both'
        ? ['New', 'Follow Up']
        : element.provider_settings.booking_available_status === 'new'
          ? ['New', 'Follow Up-d']
          : element.provider_settings.booking_available_status === 'follow-up'
            ? ['New-d', 'Follow Up']
            : ['New-d', 'Follow Up-d']
      : ['Not Available']
  }

  return (
    <div id="dashboard-container">
      <div className="row styleFilterRow">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ marginLeft: '18px', fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Clinicians</span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : totalItems}</strong>
            </Tag>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span className="dateStyling" style={{ marginRight: '10px' }}>
              Filter
            </span>
            <div style={{ position: 'relative' }}>
              <Tooltip title="Filter">
                <FilterOutlined
                  style={{ marginRight: '20px', fontSize: '18px', marginTop: '5px', cursor: 'pointer' }}
                  ref={filterButtonRef}
                  onClick={handleFilter}
                />
              </Tooltip>

              {isFilterVisible && (
                <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}>
                  <Card
                    title="Filter Options"
                    bordered={true}
                    extra={
                      <Button type="Close" onClick={handleClose}>
                        <CloseCircleOutlined />
                      </Button>
                    }
                  >
                    <FilterComponent
                      searchField={searchField}
                      setSearchField={setSearchField}
                      stateDropDown={stateDropDown}
                      insuranceDropDown={insuranceDropDown}
                      apptTypeDropDown={apptTypeDropDown}
                      apptTypeSelected={apptTypeSelected}
                      setApptTypeSelected={setApptTypeSelected}
                      stateSelected={stateSelected}
                      setStateSelected={setStateSelected}
                      insuranceSelected={insuranceSelected}
                      setInsuranceSelected={setInsuranceSelected}
                      bookingStatus={availableBookingStatus}
                      setBookingStatus={setAvailableBookingStatus}
                      timezone={timezone}
                      setTimezone={setTimezone}
                      languageSelected={languageSelected}
                      setLanguageSelected={setLanguageSelected}
                      specializationSelected={specializationSelected}
                      setSpecializationSelected={setSpecializationSelected}
                      specializationDropDown={specializationDropDown}
                      agepointsSelected={agePointsSelected}
                      setAgepointsSelected={setAgePointsSelected}
                      agepointsDropDown={agePointsDropDown}
                      treatmentPreferences={treatmentPreferences}
                      setTreatmentPreferences={setTreatmentPreferences}
                      treatmentsDropDown={treatmentsDropDown}
                      from={'Clinician'}
                    />
                  </Card>
                </div>
              )}
            </div>

            <Search
              id="search"
              className="selectInputBox"
              placeholder="Search Clinician"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              onSearch={handleSearch}
              allowClear
            />
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit
          }}
          bordered={true}
          onChange={handleTableChange}
        >
          <Column
            title="Name"
            key="clinicianCombined"
            render={(record) => (
              <div>
                <strong>{record.clinician}</strong>
                <div>{record.email}</div>
              </div>
            )}
          />

          <Column
            title="Specialization"
            key="specialization"
            width={300}
            render={(record) => (
              <>
                {record.specialization ? (
                  record?.specialization?.sort()?.map((specialization) => (
                    <span key={specialization}>
                      <Tag color="blue" style={{ margin: '2px' }}>
                        {specialization}
                      </Tag>
                    </span>
                  ))
                ) : (
                  <Tag color="red">Not Available</Tag>
                )}
              </>
            )}
          />

          <Column
            title="Age Points"
            key="agepoints"
            render={(record) => (
              <>
                {record.agepoints ? (
                  record?.agepoints?.map((agepoint) => (
                    <span key={agepoint}>
                      <Tag color="blue" style={{ margin: '2px' }}>
                        {agepoint}
                      </Tag>
                    </span>
                  ))
                ) : (
                  <Tag color="red">Not Available</Tag>
                )}
              </>
            )}
          />

          <Column
            title="Appointment Types"
            dataIndex="appointmentTypes"
            key="appointmentTypes"
            render={(appointmentTypes, record) => (
              <>
                {appointmentTypes?.length ? (
                  <>
                    {appointmentTypes.split(',').map((tagFor) => {
                      return (
                        <>
                          <Tag color="blue" key={tagFor} style={{ margin: '2px' }}>
                            {tagFor}
                          </Tag>
                          <br />
                        </>
                      )
                    })}
                  </>
                ) : null}
              </>
            )}
          />

          <Column
            title="Treatment Preference"
            key="treatments"
            render={(record) => (
              <>
                {record.treatments ? (
                  record?.treatments?.map((treatment) => (
                    <span key={treatment}>
                      <Tag color="blue" style={{ margin: '2px' }}>
                        {treatment}
                      </Tag>
                    </span>
                  ))
                ) : (
                  <Tag color="red">Not Available</Tag>
                )}
              </>
            )}
          />

          <Column
            title="Available State and Insurance"
            dataIndex="stateInsurance"
            key="stateInsurance"
            render={(stateInsurance) => <Button onClick={() => handleStateInsuranceDetails(stateInsurance)}>Details</Button>}
          />
          <Column title="Time zone" dataIndex="timezone" key="timezone" />

          <Column
            title="Language"
            key="language"
            render={(record) => (
              <>
                {record.language ? (
                  record?.language?.sort()?.map((language) => (
                    <span key={language}>
                      <Tag color="blue" style={{ margin: '2px' }}>
                        {language}
                      </Tag>
                    </span>
                  ))
                ) : (
                  <Tag color="red">Not Available</Tag>
                )}
              </>
            )}
          />
          <Column
            title="Doxy Link"
            dataIndex="doxyLink"
            key="doxyLink"
            render={(doxyLink) => (
              <>
                {doxyLink ? (
                  <a href={doxyLink} target="_blank" rel="noreferrer">
                    Link
                  </a>
                ) : null}
              </>
            )}
          />
          <Column
            title="Booking Status"
            dataIndex="bookingStatus"
            key="bookingStatus"
            render={(bookingStatus) => (
              <>
                {Array.isArray(bookingStatus) && bookingStatus.length > 0
                  ? bookingStatus.map((status, index) => (
                    <Tag color={status === 'New' || status === 'Follow Up' ? 'green' : 'red'} key={index}>
                      {status.split('-')[0]}
                    </Tag>
                  ))
                  : '--'}
              </>
            )}
          />

          <Column
            title="Special Notes"
            dataIndex="specialNotes"
            key="specialNotes"
          />

          <Column
            title="Action"
            dataIndex="id"
            key="id"
            render={(id) => (
              <>
                <Tooltip title={'Edit'}>
                  <Link to={`/onboard-clinicians/${id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    <EditOutlined style={{ marginRight: '10px', cursor: 'pointer' }} />
                  </Link>
                </Tooltip>
              </>
            )}
          />
        </Table>
      </Spin>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
        // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
        style={{ minHeight: '300px', fontSize: '1rem' }}
      >
        {modalComponent}
      </Modal>
    </div>
  )
}

export default CliniciansDetails
