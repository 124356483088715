import axios from 'axios'
import { json } from 'react-router-dom'
import { BACKEND_URL } from '../utils/urls'

export default class ClinicianService {
  async getAllData(pageNo, pageSize, intent, data) {
    try {
      const url = `${BACKEND_URL}/api/providers`
      const config = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          pageNo: pageNo,
          limit: pageSize
        }
      }

      const payload = {
        intent: intent,
        payload: data,
        context: null
      }

      const response = await axios.post(url, payload, config)
      if (response.status === 201 && response?.data) {
        const resData = await response.data
        // console.log(resData)
        return resData
      } else if (response.status === 404) {
        // console.log(response)
        return { data: [] }
      }
    } catch (error) {
      console.log(error.response.data.error)
      let errorMessage = ''
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error.message
      }

      throw new Error(errorMessage)
    }
  }

  async getSpecialization() {
    const response = await axios.get(`${BACKEND_URL}/api/getAllProviderSpecialization`)
    if (response.status === 200 && response.data) {
      const resData = await response.data
      // console.log(resData)
      return resData
    } else {
      throw json(
        { message: 'Could not fetch Provider Specialization.' },
        {
          status: 500
        }
      )
    }
  }

  async getAgePoints() {
    const response = await axios.get(`${BACKEND_URL}/api/getAllProviderAgePoints`)
    if (response.status === 200 && response.data) {
      const resData = await response.data
      // console.log(resData)
      return resData
    } else {
      throw json(
        { message: 'Could not fetch Provider AgePoints.' },
        {
          status: 500
        }
      )
    }
  }

  async getTreatmentPreferences() {
    const response = await axios.get(`${BACKEND_URL}/api/getAllProviderTreatmentPreference`)
    if (response.status === 200 && response.data) {
      const resData = await response.data
      return resData
    } else {
      throw json(
        { message: 'Could not fetch Provider Treatments.' },
        {
          status: 500
        }
      )
    }
  }
}

export const { getAllData, getSpecialization, getAgePoints, getTreatmentPreferences } = new ClinicianService()
